import { Form, Formik } from "formik";
import { Heading } from "../../../../atoms/Heading";
import PasswordField from "../../../../atoms/PasswordField";
import PasswordStrengthIndicator from "../../../../atoms/PasswordStrengthIndicator";
import { useState } from "react";
import * as yup from "yup";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { postData } from "../../../../../Services";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import CustomButton from "../../../../atoms/CustomButton";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  // Form
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Please enter the correct format"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handlePassChange = async (
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched
  ) => {
    const dataToSend = {
      password: values.password,
    };

    try {
      const res = await postData({
        endpoint: "adminAuth/changePassword",
        token: cookies.t,
        data: {},
        params: { id: cookies.uid, password: values.password },
      });

      if (res) {
        toast.success("Password Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        setFieldValue("password", "");
        setFieldValue("confirmPassword", "");
        setFieldError("confirmPassword", "");
        setFieldTouched("confirmPassword", false);
        setFieldError("password", "");
        setFieldTouched("password", false);
        // window.location.reload();
        // navigate(0);
      }
    } catch (error) {}
  };

  return (
    <>
      <Heading className="HeadingStyle mb-1">Change Password</Heading>
      <p className="mb-4">Enter your new password to update your password.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        // onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          touched,
          errors,
          values,
          isValid,
          dirty,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* <div className="row mt-2"> */}
            <div className="col-6">
              <PasswordField
                //   label="Password"
                id="password"
                name="password"
                value={values.password}
                onChange={(e) => setFieldValue("password", e.target.value)}
                onBlur={handleBlur}
                placeholder="Enter New Password"
                className={`${
                  (touched.password || isSubmitting) && errors.password
                    ? "error-input"
                    : ""
                }`}
              />
              {(touched.password || isSubmitting) && errors.password && (
                <div className="error-input-message">{errors.password}</div>
              )}
            </div>
            {/* </div> */}

            <div className="col-6 mt-3">
              <PasswordField
                // label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={(e) =>
                  setFieldValue("confirmPassword", e.target.value)
                }
                onBlur={handleBlur}
                placeholder="Confirm Password"
                className={`${
                  (touched.confirmPassword || isSubmitting) &&
                  errors.confirmPassword
                    ? "error-input"
                    : ""
                }`}
              />
              {(touched.confirmPassword || isSubmitting) &&
                errors.confirmPassword && (
                  <div className="error-input-message">
                    {errors.confirmPassword}
                  </div>
                )}
            </div>
            <div className="col-6 mt-3">
              <div className="d-flex gap-3 justify-content-end">
                <CustomButton
                  handleClick={() => navigate(-1)}
                  size="btn-lg"
                  text="Back"
                />

                <CustomButton
                  type="btn-primary"
                  handleClick={() =>
                    handlePassChange(
                      values,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched
                    )
                  }
                  size="btn-lg"
                  text="Update"
                  buttonType="submit"
                />
              </div>
            </div>

            <PasswordStrengthIndicator
              password={values.password}
              isPasswordValid={isPasswordValid}
              touched={touched.password}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
export default ChangePassword;
