import PropTypes from "prop-types";
const Lock = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M10.1092 4.9639V4.1092C10.1092 1.83975 8.26947 0 6.00002 0C3.73056 0 1.89084 1.83975 1.89084 4.10918V4.96388C0.811188 5.51485 0.131082 6.62408 0.129761 7.8362V10.7713C0.131714 12.5537 1.57607 13.998 3.3584 14H8.64163C10.424 13.998 11.8683 12.5537 11.8703 10.7713V7.83622C11.869 6.62411 11.1889 5.51485 10.1092 4.9639ZM6.00002 1.76108C7.29683 1.76108 8.34811 2.81236 8.34811 4.10918V4.60756H3.65192V4.10918C3.65192 2.81236 4.7032 1.76108 6.00002 1.76108ZM10.1092 10.7713C10.1092 11.5818 9.45217 12.2389 8.64166 12.2389H3.35842C2.54791 12.2389 1.89087 11.5818 1.89087 10.7713V7.83622C1.89087 7.02571 2.54791 6.36867 3.35842 6.36867H8.64166C9.45217 6.36867 10.1092 7.02571 10.1092 7.83622V10.7713Z"
        fill="#3B424A"
        className={className}
      />
      <path
        d="M5.70659 8.12988H6.2936C6.77991 8.12988 7.17414 8.52412 7.17414 9.01042C7.17414 9.49672 6.77991 9.89096 6.2936 9.89096H5.70659C5.22028 9.89096 4.82605 9.49672 4.82605 9.01042C4.82605 8.52412 5.22028 8.12988 5.70659 8.12988Z"
        fill="#3B424A"
        className={className}
      />
    </svg>
  );
};
Lock.propTypes = {
  className: PropTypes.string, // expects a string for additional class name
};
export default Lock;
